<template>
  <div
    class="instagram"
    :class="{
      [`mp--${mobilePadding}`]: !!mobilePadding,
      [`mm--${mobileMargin}`]: !!mobileMargin,
      [`dp--${desktopPadding}`]: !!desktopPadding,
      [`dm--${desktopMargin}`]: !!desktopMargin,
      'force-mobile': !!forceMobile,
    }"
  >
    <div
      v-if="headline || account"
      class="headline-container"
      :class="{ 'has-sub-headline': subHeadline }"
    >
      <div class="row-content">
        <InstagramIcon class="logo" />
        <div v-if="headline" class="headline">
          <span>{{ headline }}</span>
        </div>
      </div>
      <div class="sub-headline">{{ subHeadline }}</div>
      <a class="account" :href="instagramUrl" target="_blank">
        <span v-if="account">{{ account }}</span>
      </a>
    </div>
    <div
      v-if="images && images.length"
      class="image-container"
      :class="{ 'single-image': images.length === 1 }"
    >
      <InstagramImage
        v-for="(image, imageIndex) in images"
        :key="'image-' + imageIndex"
        class="image"
        :image="image"
      />
    </div>
    <div v-else class="no-posts">
      <span>{{ $t('Instagram_Collection_No_Posts') }}</span>
    </div>
    <div
      v-if="loadMore && moreItemsToShow"
      class="load-more"
      @click="$emit('clickedLoadMore')"
    >
      <button class="button simple" :disabled="loading">
        <span class="label">{{ $t('Instagram_Collection_More') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import InstagramIcon from '~/assets/icons/instagram-black-and-white.svg'
import InstagramImage from '~/components/instagram-yotpo/InstagramImage'

export default {
  name: 'InstagramGallery',
  components: {
    InstagramIcon,
    InstagramImage,
  },
  props: {
    headline: {
      type: String,
      required: false,
      default: '',
    },
    subHeadline: {
      type: String,
      required: false,
      default: '',
    },
    account: {
      type: String,
      required: false,
      default: '',
    },
    images: {
      type: Array,
      required: false,
      default: () => [],
    },
    loadMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    moreItemsToShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    productId: {
      type: String,
      required: false,
      default: '',
    },
    mobilePadding: {
      type: String,
      required: false,
      default: '',
    },
    mobileMargin: {
      type: String,
      required: false,
      default: '',
    },
    desktopPadding: {
      type: String,
      required: false,
      default: '',
    },
    desktopMargin: {
      type: String,
      required: false,
      default: '',
    },
    forceMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    instagramUrl() {
      return `https://www.instagram.com/` + this.account.replace(/^@/, '')
    },
  },
}
</script>

<style lang="scss" scoped>
.instagram {
  padding-top: spacing('medium');
  padding-bottom: spacing('medium');

  .headline-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    justify-content: center;
    column-gap: 0.4rem;
    padding-top: spacing('small');
    padding-bottom: spacing('small');
    margin-left: auto;
    margin-right: auto;

    &.has-sub-headline {
      flex-direction: column;
    }

    .row-content {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;
    }

    .logo {
      width: 3rem;
      height: 3rem;
    }

    .headline {
      @include h--small;

      margin-top: 0.5rem;
      margin-bottom: 0;

      text-align: center;
    }

    .account {
      @include p--large;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
      color: $blue;
      font-weight: 100;
    }

    .sub-headline,
    .account {
      margin-top: 0.8rem;
    }
  }
  .image-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
  }

  .no-posts {
    @include p--large;

    padding: 0 24px;
  }

  .load-more {
    @include p--medium;

    text-align: center;
    padding-top: 24px;

    .button {
      border-radius: 0;

      .label {
        color: $black;
      }
    }
  }
}
@media screen and (max-width: $tablet-landscape) {
  .instagram {
    .image-container {
      &.single-image {
        display: flex;
        justify-content: center;

        > .image {
          width: 60vw;
        }
      }
    }
  }
}
// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .instagram {
    padding-top: spacing('large');
    padding-bottom: spacing('large');

    .headline-container {
      padding-top: spacing('medium');
      padding-bottom: spacing('medium');

      .row-content {
        justify-content: flex-start;
        align-items: baseline;
        column-gap: 2rem;
      }

      .logo {
        align-self: center;
        width: 4rem;
        height: 4rem;
      }

      .headline {
        @include h--medium;

        margin-top: 0;
        margin-bottom: 0;
      }

      .sub-headline {
        margin-top: 1rem;
      }
    }

    .image-container {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 4px;
    }

    &.force-mobile {
      .image-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .no-posts {
      padding: 0 40px;
    }

    .load-more {
      @include p--large;

      padding-top: 40px;
    }
  }
}
</style>
