<template>
  <div class="instagram-controller">
    <InstagramGallery
      v-if="feedItems.length"
      :headline="headline"
      :account="account"
      :images="feedItems"
      :load-more="loadMore"
      :more-items-to-show="moreItemsToShow"
      :loading="loading"
      :mobile-padding="mobilePadding"
      :mobile-margin="mobileMargin"
      :desktop-padding="desktopPadding"
      :desktop-margin="desktopMargin"
      @clickedLoadMore="loadFeed"
    />
  </div>
</template>

<script>
import InstagramGallery from '~/components/instagram-yotpo/InstagramGallery'

export default {
  name: 'InstagramGalleryController',
  components: { InstagramGallery },
  props: {
    headline: {
      type: String,
      required: false,
      default: '',
    },
    album: {
      type: String,
      required: true,
    },
    limit: {
      type: String,
      require: false,
      default: '15',
    },
    loadMore: {
      type: Boolean,
      required: false,
      default: true,
    },
    mobilePadding: {
      type: String,
      required: false,
      default: '',
    },
    mobileMargin: {
      type: String,
      required: false,
      default: '',
    },
    desktopPadding: {
      type: String,
      required: false,
      default: '',
    },
    desktopMargin: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      page: 1,
      loading: true,
      feedItems: [],
      moreItemsToShow: false,
    }
  },
  computed: {
    account() {
      return this.$store.state.yotpo.settings.visualUGCinstagramAccountName
    },
  },
  watch: {
    album(oldVal, _) {
      if (oldVal) {
        this.page = 1
        this.feedItems = []
        this.loadFeed()
      }
    },
  },
  mounted() {
    this.loadFeed()
  },
  methods: {
    loadFeed() {
      this.$backendApi
        .get(`yotpo/album/${this.album}/${parseInt(this.limit)}/${this.page}`)
        .then((res) => {
          if (res.status === 200) {
            const keys = this.feedItems?.map((i) => i.image_id)
            if (keys?.length && res.data?.data?.images) {
              this.feedItems = this.feedItems.concat(
                res.data.data.images.filter((i) => !keys.includes(i.image_id))
              )
            } else {
              this.feedItems = res.data?.data?.images || []
            }
            this.page++
            this.moreItemsToShow =
              res.data?.data?.pagination.total > this.feedItems.length
          }
        })
        .catch((err) => {
          this.moreItemsToShow = false
          console.log(err)
        })
        .finally((this.loading = false))
    },
  },
}
</script>
