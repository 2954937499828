<template>
  <div
    v-if="image.media_type === 'image'"
    class="instagram-image mobile-format--square"
  >
    <ResponsiveImage
      :lazyload="true"
      :mobile-src="image.original_image_url"
      mobile-display-size="50vw"
      desktop-display-size="20vw"
      alt="Instagram image"
      class="instagram-img"
    />
    <div class="content">
      <div class="icon">
        <CartIcon />
      </div>
    </div>
    <div class="overlay" @click="openPopup" />
  </div>
</template>

<script>
import CartIcon from '~/static/images/cart.svg'
let InstagramPopup

export default {
  name: 'InstagramImage',
  components: {
    CartIcon,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async openPopup() {
      if (!InstagramPopup) {
        InstagramPopup = await import('../instagram-yotpo/InstagramPopup.vue')
      }
      this.$modal.show(
        InstagramPopup.default,
        {
          modal: 1,
          image: this.image,
        },
        {
          adaptive: true,
          height: 'auto',
          scrollable: true,
          transition: 'slide-in-top-fade',
          classes: ['instagram-popup'],
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.instagram-image {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .content {
    .icon {
      position: absolute;
      right: 12px;
      bottom: 12px;

      svg {
        width: 12px;
        fill: $white;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .instagram-image {
    .content {
      .icon {
        width: 24px;
        height: 24px;
        background-color: $white;
        border-radius: 50%;
        right: 16px;
        bottom: 16px;

        svg {
          @include dead-center;

          padding-bottom: 1px;

          path {
            fill: $black;
          }
        }
      }
    }
  }
}
</style>
