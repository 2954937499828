var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instagram",class:{
    [`mp--${_vm.mobilePadding}`]: !!_vm.mobilePadding,
    [`mm--${_vm.mobileMargin}`]: !!_vm.mobileMargin,
    [`dp--${_vm.desktopPadding}`]: !!_vm.desktopPadding,
    [`dm--${_vm.desktopMargin}`]: !!_vm.desktopMargin,
    'force-mobile': !!_vm.forceMobile,
  }},[(_vm.headline || _vm.account)?_c('div',{staticClass:"headline-container",class:{ 'has-sub-headline': _vm.subHeadline }},[_c('div',{staticClass:"row-content"},[_c('InstagramIcon',{staticClass:"logo"}),_vm._v(" "),(_vm.headline)?_c('div',{staticClass:"headline"},[_c('span',[_vm._v(_vm._s(_vm.headline))])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"sub-headline"},[_vm._v(_vm._s(_vm.subHeadline))]),_vm._v(" "),_c('a',{staticClass:"account",attrs:{"href":_vm.instagramUrl,"target":"_blank"}},[(_vm.account)?_c('span',[_vm._v(_vm._s(_vm.account))]):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.images && _vm.images.length)?_c('div',{staticClass:"image-container",class:{ 'single-image': _vm.images.length === 1 }},_vm._l((_vm.images),function(image,imageIndex){return _c('InstagramImage',{key:'image-' + imageIndex,staticClass:"image",attrs:{"image":image}})}),1):_c('div',{staticClass:"no-posts"},[_c('span',[_vm._v(_vm._s(_vm.$t('Instagram_Collection_No_Posts')))])]),_vm._v(" "),(_vm.loadMore && _vm.moreItemsToShow)?_c('div',{staticClass:"load-more",on:{"click":function($event){return _vm.$emit('clickedLoadMore')}}},[_c('button',{staticClass:"button simple",attrs:{"disabled":_vm.loading}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('Instagram_Collection_More')))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }